import Vue from 'vue';
import StoryPopup from '@/components/StoryPopup';

let popup = null;

export default {
  open(id) {
    const PopupClass = Vue.extend(StoryPopup);
    popup = new PopupClass({
      propsData: {
        id
      }
    });
    popup.$on('close', () => {
      document.querySelector("#app").firstChild.removeChild(popup.$el);
      popup.$destroy();
      popup = null;
    });
    popup.$mount();
    document.querySelector("#app").firstChild.appendChild(popup.$el);
  }
};