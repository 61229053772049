var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.connectStory)?_c('div',{staticClass:"story-popup"},[_c('svg',{staticClass:"closePopup",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"52.132","height":"52.132","viewBox":"0 0 52.132 52.132"},on:{"click":_vm.onClose}},[_c('g',{attrs:{"id":"Group_787","data-name":"Group 787","transform":"translate(-1803)"}},[_c('rect',{attrs:{"id":"Rectangle_720","data-name":"Rectangle 720","width":"52.132","height":"52.132","transform":"translate(1803)","fill":"#e13036"}}),_c('text',{attrs:{"id":"Close","transform":"translate(1812 31)","fill":"#fff","font-size":"12","font-family":"Poppins-SemiBold, Poppins","font-weight":"600"}},[_c('tspan',{attrs:{"x":"0","y":"0"}},[_vm._v("Close")])])])]),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"image",style:({
        'background-image': 'url(' + _vm.connectStory.mainImageUrl + ')',
      })}),_c('div',{staticClass:"description"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.connectStory.title))]),_c('div',{staticClass:"date-by"},[_vm._v(" by "+_vm._s(_vm.connectStory.author ? _vm.connectStory.author.firstName + " " + _vm.connectStory.author.lastName : "")+" - "+_vm._s(_vm._f("moment")(_vm.connectStory.publishDate,"DD.MM.YYYY"))+" ")]),_c('div',{staticClass:"text"},[_c('VuePerfectScrollbar',{staticClass:"scroll-area",domProps:{"innerHTML":_vm._s(_vm.connectStory.content)}})],1),_c('div',{staticClass:"discover"},[_c('p',{attrs:{"id":"title"}},[_vm._v("Discover more")]),(_vm.connectStory.experiences.length > 0)?_c('div',{staticClass:"relate"},[_c('carousel',{staticClass:"post-carousel",attrs:{"items":3,"margin":10,"nav":false,"dots":false}},[_c('template',{slot:"prev"},[_c('span',{staticClass:"owl-custom-prev"},[_c('img',{attrs:{"src":_vm.imgs.leftArrow}})])]),_c('template',{slot:"next"},[_c('span',{staticClass:"owl-custom-next"},[_c('img',{attrs:{"src":_vm.imgs.rightArrow}})])]),_vm._l((_vm.connectStory.experiences),function(experience){return _c('div',{key:experience},[_c('div',{staticClass:"card",style:({
                  backgroundImage: ("url(" + (experience.thumbnailUrl) + ")"),
                })})])})],2)],1):_vm._e(),(_vm.connectStory.experiences.length == 0)?_c('div',{staticClass:"relate"},[_c('carousel',{staticClass:"post-carousel",attrs:{"items":3,"margin":10,"nav":false,"dots":false}},[_c('template',{slot:"prev"},[_c('span',{staticClass:"owl-custom-prev"},[_c('img',{attrs:{"src":_vm.imgs.leftArrow}})])]),_c('template',{slot:"next"},[_c('span',{staticClass:"owl-custom-next"},[_c('img',{attrs:{"src":_vm.imgs.rightArrow}})])]),_c('div',{staticClass:"card",style:({
                backgroundImage: ("url(" + (_vm.connectStory.mainImageUrl) + ")"),
              })}),_c('div',{staticClass:"card",style:({
                backgroundImage: ("url(" + (_vm.connectStory.mainImageUrl) + ")"),
              })}),_c('div',{staticClass:"card",style:({
                backgroundImage: ("url(" + (_vm.connectStory.mainImageUrl) + ")"),
              })})],2)],1):_vm._e()])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }