<template>
  <div class="EventPopup">
    <div
      class="image-bg"
      :style="{ 'background-image': 'url(' + event.mainImageUrl + ')' }"
    ></div>
    <div class="right-content">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52.132"
        height="52.132"
        viewBox="0 0 52.132 52.132"
        class="closePopup"
        @click="close"
      >
        <g id="Group_787" data-name="Group 787" transform="translate(-1803)">
          <rect
            id="Rectangle_720"
            data-name="Rectangle 720"
            width="52.132"
            height="52.132"
            transform="translate(1803)"
            fill="#e13036"
          />
          <text
            id="Close"
            transform="translate(1812 31)"
            fill="#fff"
            font-size="12"
            font-family="Poppins-SemiBold, Poppins"
            font-weight="600"
          >
            <tspan x="0" y="0">Close</tspan>
          </text>
        </g>
      </svg>
      <div class="title">{{ event.title }}</div>
      <div class="date"></div>
      <VuePerfectScrollbar class="scroll-area">
        <div class="content">
          <span v-html="event.content"></span>
        </div>
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import axios from "axios";
import * as moment from "moment";
export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      event: [],
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    load() {
      if (!this.id) return;

      const url = process.env.VUE_APP_API_URI;
      const postData = {
        query: `query event($id: ID) { event(id: $id) { id title name content address organizer websiteLink mainImageUrl } }`,
        variables: {
          id: this.id,
        },
      };
      axios
        .post(url, postData, {
          headers: {
            "X-API-Key": process.env.VUE_APP_API_KEY,
          },
        })
        .then((res) => {
          this.event = res.data.data.event;
        })
        .catch(console.error);
    },
    getMonthEvent(startDate, endDate) {
      return moment(startDate).format("MMM");
    },
    getDayEvent(startDate, endDate) {
      const monthStart = moment(startDate).format("MMM");
      const monthEnd = moment(endDate).format("MMM");
      if (!moment(startDate).isSame(endDate, "month")) {
        return moment(startDate).format("D");
      } else {
        if (moment(startDate).isSame(endDate, "day")) {
          return moment(startDate).format("D");
        } else {
          return (
            moment(startDate).format("D") + " - " + moment(endDate).format("D")
          );
        }
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.EventPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1500;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
  display: flex;
  padding: 30px 40px;
  .image-bg {
    position: relative;
    width: 50%;
    height: 100%;
    background-color: gray;
    background-size: cover;
    background-position: center;
  }
  .closePopup {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .right-content {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50%;
    padding-left: 10%;
    .title {
      padding-top: 10%;
      font-size: 30px;
      font-weight: bold;
    }
    .date {
      font-size: 18px;
      font-weight: bold;
    }
    .content {
      padding-top: 5%;
      font-size: 15px;
      max-width: 80%;
    }
    .discover {
      padding-top: 10%;
      font-size: 18px;
    }
    .scroll-area {
      position: relative;
      margin: auto;
      height: 50%;
    }
  }
}
</style>
