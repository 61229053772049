import Vue from 'vue';
import EventPopup from '@/components/EventPopup';

let popup = null;

export default {
    open(id) {
      const PopupClass = Vue.extend(EventPopup);
      popup = new PopupClass({
        propsData: {
          id
        }
      });
      popup.close = () => {
        document.querySelector("#app").firstChild.removeChild(popup.$el);
        popup.$destroy();
        popup = null;
      };
      popup.$mount();
      document.querySelector("#app").firstChild.appendChild(popup.$el);
    }
  };