<template>
  <div class="story-popup" v-if="connectStory">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52.132"
      height="52.132"
      viewBox="0 0 52.132 52.132"
      class="closePopup"
      @click="onClose"
    >
      <g id="Group_787" data-name="Group 787" transform="translate(-1803)">
        <rect
          id="Rectangle_720"
          data-name="Rectangle 720"
          width="52.132"
          height="52.132"
          transform="translate(1803)"
          fill="#e13036"
        />
        <text
          id="Close"
          transform="translate(1812 31)"
          fill="#fff"
          font-size="12"
          font-family="Poppins-SemiBold, Poppins"
          font-weight="600"
        >
          <tspan x="0" y="0">Close</tspan>
        </text>
      </g>
    </svg>
    <div class="detail">
      <div
        class="image"
        :style="{
          'background-image': 'url(' + connectStory.mainImageUrl + ')',
        }"
      ></div>
      <div class="description">
        <div class="title">{{ connectStory.title }}</div>
        <div class="date-by">
          by
          {{
            connectStory.author
              ? connectStory.author.firstName +
                " " +
                connectStory.author.lastName
              : ""
          }}
          - {{ connectStory.publishDate | moment("DD.MM.YYYY") }}
        </div>
        <div class="text">
          <VuePerfectScrollbar
            class="scroll-area"
            v-html="connectStory.content"
          ></VuePerfectScrollbar>
        </div>
        <div class="discover">
          <p id="title">Discover more</p>

          <div class="relate" v-if="connectStory.experiences.length > 0">
            <carousel
              class="post-carousel"
              :items="3"
              :margin="10"
              :nav="false"
              :dots="false"
            >
              <template slot="prev">
                <span class="owl-custom-prev">
                  <img :src="imgs.leftArrow" />
                </span>
              </template>

              <template slot="next">
                <span class="owl-custom-next">
                  <img :src="imgs.rightArrow" />
                </span>
              </template>

              <div
                v-for="experience in connectStory.experiences"
                :key="experience"
              >
                <div
                  class="card"
                  :style="{
                    backgroundImage: `url(${experience.thumbnailUrl})`,
                  }"
                ></div>
              </div>
            </carousel>
          </div>

          <div class="relate" v-if="connectStory.experiences.length == 0">
            <carousel
              class="post-carousel"
              :items="3"
              :margin="10"
              :nav="false"
              :dots="false"
            >
              <template slot="prev">
                <span class="owl-custom-prev">
                  <img :src="imgs.leftArrow" />
                </span>
              </template>

              <template slot="next">
                <span class="owl-custom-next">
                  <img :src="imgs.rightArrow" />
                </span>
              </template>

              <div
                class="card"
                :style="{
                  backgroundImage: `url(${connectStory.mainImageUrl})`,
                }"
              ></div>
              <div
                class="card"
                :style="{
                  backgroundImage: `url(${connectStory.mainImageUrl})`,
                }"
              ></div>
              <div
                class="card"
                :style="{
                  backgroundImage: `url(${connectStory.mainImageUrl})`,
                }"
              ></div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import axios from "axios";
import carousel from "vue-owl-carousel";
import leftArrow from "@/assets/img/left-arrow.svg";
import rightArrow from "@/assets/img/right-arrow.svg";

export default {
  name: "StoryPopup",
  components: {
    carousel,
    VuePerfectScrollbar,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    // travelerTypes: {
    //   type: Array,
    //   default: () => []
    // },
    // locations: {
    //   type: Array,
    //   default: () => []
    // }
  },
  data() {
    return {
      connectStory: null,
      // story : [],
      // final: true,
      imgs: {
        leftArrow,
        rightArrow,
      },
    };
  },
  mounted() {
    document.body.style.overflow = "hidden";
    this.load();
    // this.getMoreExperience();
  },
  methods: {
    load() {
      if (!this.id) return;

      const url = process.env.VUE_APP_API_URI;
      const id = this.id;
      const postData = {
        query: `query story($id: ID) { story(id: $id) {
									id
									title
									name
									mainImageUrl
									content
  									author {
										firstName
										lastName
										screenName
									}
  									publishDate
										experiences{
											id
											name
											title
											thumbnailUrl
										}
								}
              }`,
        variables: {
          id,
        },
      };
      axios
        .post(url, postData, {
          headers: {
            "X-API-Key": process.env.VUE_APP_API_KEY,
          },
        })
        .then((res) => {
          this.connectStory = res.data.data.story;
        })
        .catch(console.error);
    },

    // getMoreExperience() {
    // const url = process.env.VUE_APP_API_URI;
    // const travelerTypes = this.travelerTypes.map(it => `"${it}"`).join(", ");
    // const locations = this.locations.map(it => `"${it}"`).join(", ");
    // const query = ` `;
    // axios
    // .post(url, { query })
    // .then(res => {
    //  this.story = res.data.data.story;
    // this.stories = stories.data;
    // this.experinces = experinces.data;
    // this.experinces = experinces.data;
    // console.log(res.data);
    // })
    // .catch(console.error);

    // },
    onClose() {
      this.$emit("close");
    },
  },
  destroyed() {
    document.body.style.overflow = "initial";
  },
};
</script>

<style lang="scss" scoped>
.story-popup {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  top: 0;
  z-index: 10;

  .closePopup {
    position: absolute;
    top: 0;
    right: 2%;
    cursor: pointer;
  }

  .detail {
    display: flex;

    .image {
      width: 50%;
      height: 100vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #999;
    }

    .description {
      width: 50%;
      height: 100vh;
      padding: 3.87% 5.47%;
      // padding: 7.87% 10.47%;
      .title {
        font-size: 35px;
        font-weight: bold;
      }

      .date-by {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px;
      }

      .discover {
        height: 22.4%;
        width: 100%;
        #title {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .relate {
        position: relative;
        width: 100%;
        height: 100%;

        .post-carousel {
          position: relative;

          .owl-custom-prev,
          .owl-custom-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 4;
            cursor: pointer;
          }

          .owl-custom-prev {
            left: 0;
          }

          .owl-custom-next {
            right: 0;
          }

          .owl-item {
            position: relative;
          }

          .owl-item.inactive {
            position: relative;
            &::before {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              z-index: 2;
              background-color: rgba(255, 255, 255, 0.77);
              pointer-events: none;
            }
          }
        }

        .card {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          display: inline-block;
          height: 198px;
          width: 95%;
          background-color: gray;
        }
      }

      .text {
        margin-bottom: 30px;

        .scroll-area {
          height: 400px;
          padding-right: 30px;
        }
      }
    }
  }
}
</style>
