<template>
  <div class="transition-container" ref="container">
    <div class="transition-slides">
      <slot></slot>
    </div>
    <div class="transition-curtains">
      <div class="curtains-wrapper">
        <div class="curtain primary-curtain"></div>
        <div class="curtain secondary-curtain"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { TimelineLite } from "gsap";

export default {
  name: "TransitionContainer",
  data() {
    return {
      slides: [],
      curtains: {
        primary: null,
        secondary: null
      },
      animationTimeline: null,
      selectedIndex: -1,
      isChanging: false
    };
  },
  methods: {
    findElements() {
      const element = this.$refs.container;
      const slidesContainer = element.querySelector(".transition-slides");
      this.slides = slidesContainer.querySelectorAll(".transition-slide");
      const curtains = element.querySelector(".transition-curtains");
      this.curtains.primary = curtains.querySelector(".primary-curtain");
      this.curtains.secondary = curtains.querySelector(".secondary-curtain");
    },
    next() {
      if (this.selectedIndex + 1 <= this.slides.length - 1) {
        this.setSelectedIndex(this.selectedIndex + 1);
      }
      this.$emit('slideIndexSelect', this.selectedIndex+1);
    },
    prev() {
      if (this.selectedIndex > 0) {
        this.setSelectedIndex(this.selectedIndex - 1);
      }
      this.$emit('slideIndexSelect', this.selectedIndex-1);
    },
    setSelectedIndex(index) {
      if (this.isChanging) return;

      this.isChanging = true;

      if (this.selectedIndex > -1) {
        if (this.selectedIndex < index) {
          this.animationTimeline.seek(0);
          this.animationTimeline.play();
        } else {
          this.animationTimeline.seek(100);
          this.animationTimeline.reverse();
        }
      }
      setTimeout(() => {
        this.selectedIndex = index;
        this.removeActiveClass();
        this.addActiveClass(this.selectedIndex);
      }, 500);
      setTimeout(() => {
        this.isChanging = false;
      }, 1000);
    },
    addActiveClass(index) {
      this.slides[index].classList.add("active");
    },
    removeActiveClass() {
      this.slides.forEach(it => it.classList.remove("active"));
    },
    addContainerEvents() {
      this.$refs.container.addEventListener("wheel", ev => {
        ev.preventDefault();
        ev.stopPropagation();
        if (ev.deltaY > 0) {
          this.next();
        } else {
          this.prev();
        }
      });
    },
    removeContainerEvents() {
      if (this.$refs.container) {
        this.$refs.container.removeEventListener("wheel");
      }
    },
    initCurtainAnimation() {
      const tl = new TimelineLite();
      tl.to([this.curtains.primary, this.curtains.secondary], 1, {
        top: "-250%"
      });
      tl.stop();
      this.animationTimeline = tl;
    }
  },
  mounted() {
    this.findElements();
    this.initCurtainAnimation();
    this.addContainerEvents();
    if (this.slides.length > 0) {
      this.setSelectedIndex(0);
    }
  },
  destroyed() {
    this.removeContainerEvents();
  }
};
</script>

<style lang="scss">
.transition-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .transition-slides {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 1;
  }

  .transition-curtains {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;

    .curtains-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .curtain {
      position: absolute;
      left: 50%;
      width: 180%;
      height: 150%;
      transform: rotateZ(-20deg) translateX(-50%);
    }

    .primary-curtain {
      background-color: #e13036;
      z-index: 2;
      top: 180%;
    }

    .secondary-curtain {
      background-color: #fff;
      z-index: 1;
      top: 720%;
    }
  }
}
</style>
