<template>
  <div class="contents-board">
    <section class="left-section" v-if="leftfilter == true">
      <div class="search-form-container">
        <form class="search-form" @submit.prevent="onSearch">
          <input type="text" class="search-input" v-model="search" placeholder="Search" />
        </form>
      </div>
      <ul class="filter-content-types">
        <li>
          <label for="content-type-all">
            <input type="checkbox" id="content-type-all" @change="toggleSelectAll" />
            All
          </label>
        </li>
        <li v-for="ct in contentTypes" :key="ct.type">
          <label :for="`content-type-${ct.type}`">
            <input
              type="checkbox"
              :id="`content-type-${ct.type}`"
              @change="toggleFilter(ct)"
              :checked="selectedFilters.indexOf(ct.type) > -1"
            />
            {{ct.title}}
          </label>
        </li>
      </ul>
    </section>
    <section class="right-section">
      <div
        class="columns-container"
        ref="container"
        :class="{'columns-event' : (leftfilter=='false')?true:false}"
      >
        <template v-if="contents && contents.length > 0">
          <div
            v-for="(column, columnIndex) in layout"
            :key="columnIndex"
            :class="['board-column', 'column-' + (columnIndex + 1)]"
          >
            <div
              v-for="content in column"
              :key="content.id"
              class="content"
              @click="() => onClickContent(content)"
            >
              <div
                class="content-thumbnail"
                :style="{ backgroundImage: content.thumbnailUrl ? `url(${content.thumbnailUrl})` : '' }"
              >
                <div
                  class="content-type"
                  v-if="content.type === 'experiences' || content.type === 'experience'"
                >Experience</div>
                <div
                  class="content-type"
                  v-else-if="content.type === 'tours' || content.type === 'tour'"
                >Tours</div>
                <div
                  class="content-type"
                  v-else-if="content.type === 'stories' || content.type === 'story'"
                >Stories</div>
                <div
                  class="content-type"
                  v-else-if="content.type === 'offers' || content.type === 'offer'"
                >Offers</div>
                <div
                  class="content-type"
                  v-else-if="content.type === 'event' || content.type === 'events'"
                >Event</div>
                <div class="content-type" v-else>Campaigns</div>
              </div>
              <h4 class="content-title">
                <router-link :to="{ name: 'map', query: { exp: content.name } }">{{content.title}}</router-link>
              </h4>
              <div class="content-description">{{content.description | truncate(descriptionLength)}}</div>
              <div class="explore-button">Explore</div>
            </div>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import EventPopupManager from "@/services/EventPopupManager";
import StoryPopupManager from "../services/StoryPopupManager";
import * as axios from "axios";

export default {
  name: "ContentsBoard",
  props: {
    travelerTypes: {
      type: Array,
      default: () => []
    },
    locations: {
      type: Array,
      default: () => []
    },
    descriptionLength: {
      type: Number,
      default: 100
    },
    perPage: {
      type: Number,
      default: 120
    },
    scrollSpeed: {
      type: Number,
      default: 20
    },
    leftfilter: {
      type: Boolean,
      default: true
    },
    types: {
      type: Array
    }
  },
  data() {
    return {
      scrollTop: 0,
      contents: [],
      final: false,
      next: "",
      search: "",
      selectedFilters: [],
      contentTypes: [
        {
          type: "experience",
          title: "Experiences"
        },
        {
          type: "tour",
          title: "Tours"
        },
        {
          type: "story",
          title: "Stories"
        },
        {
          type: "offer",
          title: "Offers"
        },
        {
          type: "campaign",
          title: "Campaigns"
        }
      ]
    };
  },
  computed: {
    layout() {
      const columns = [[], [], []];
      const length = columns.length;
      let columnIndex = 0;
      if (this.contents && this.contents.length > 0) {
        this.contents.forEach((item, index) => {
          columns[columnIndex].push(item);
          columnIndex++;
          if (columnIndex >= length) {
            columnIndex = 0;
          }
        });
      }
      return columns;
    }
  },
  methods: {
    load() {
      const types =
        this.selectedFilters && this.selectedFilters.length > 0
          ? this.selectedFilters
          : this.types;

      const hasTypeFilter = types && types.length > 0;
      const hasTravelerTypeFilter =
        this.travelerTypes && this.travelerTypes.length > 0;
      const hasLocationFilter = this.locations && this.locations.length > 0;

      const data = {
        query: `query { contents(limit: ${this.perPage}${this.next ? `, next: "${next}"`: ''}${
          hasTravelerTypeFilter
            ? ", travelerTypes: [" +
              this.travelerTypes.map(id => `"${id}"`).join(",") +
              "]"
            : ""
        }${
          hasLocationFilter
            ? ", locations: [" +
              this.locations.map(id => `"${id}"`).join(",") +
              "]"
            : ""
        }${this.search ? `, search: "${this.search}"` : ""}${
          hasTypeFilter
            ? `, types: [${types.map(t => `"${t}"`).join(",")}]`
            : ""
        }) { next hasNext results { id title name thumbnailUrl description type } } }`
      };

      const url = process.env.VUE_APP_API_URI;
      axios
        .post(url, data, {
          headers: {
            'X-API-Key': process.env.VUE_APP_API_KEY
          }
        })
        .then(res => {
          const { results: contents, hasNext, next } = res.data.data.contents;
          this.final = !hasNext;
          this.next = next;
          this.contents = this.contents.concat(contents);
        })
        .catch(console.error);
    },
    reset() {
      this.final = true;
      this.contents = [];
      this.next = "";
      this.scrollTop = 0;
    },
    reload() {
      this.reset();
      this.load();
    },
    loadNext() {
      this.load();
    },
    onClickContent(content) {
      if (content.type === "event") {
        EventPopupManager.open(content.id);
      } else if (content.type === "story") {
        StoryPopupManager.open(content.id);
      } else {
        this.$router.push({ name: "map", query: { exp: content.name } });
      }
    },
    onSearch() {
      this.reload();
    },
    toggleFilter(ct) {
      const index = this.selectedFilters.indexOf(ct.type);
      if (index > -1) {
        this.selectedFilters.splice(index, 1);
      } else {
        this.selectedFilters.push(ct.type);
      }
      this.reload();
    },
    toggleSelectAll(ev) {
      if (ev.target.checked) {
        this.selectedFilters = this.contentTypes.map(it => it.type);
      } else {
        this.selectedFilters = [];
      }
      this.reload();
    },
    addEvents() {
      if (this.$refs.container) {
        this.$refs.container.addEventListener("wheel", this.wheelEventHandler);
      }
    },
    removeEvents() {
      if (this.$refs.container) {
        this.$refs.container.removeEventListener(
          "wheel",
          this.wheelEventHandler
        );
      }
    },
    wheelEventHandler(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      const container = this.$refs.container;
      const direction = (ev.deltaY > 0 ? 1 : -1) * this.scrollSpeed;
      if (this.scrollTop <= 0 && direction < 0) return;

      this.scrollTop += direction;
      const columns = container.querySelectorAll(".board-column");
      columns[0].style.transform = `translateY(${this.scrollTop * 3 * -1}px)`;
      columns[1].style.transform = `translateY(${this.scrollTop * 4 * -1}px)`;
      columns[2].style.transform = `translateY(${this.scrollTop * 3.5 * -1}px)`;
      container.style.marginBottom = `${this.scrollTop * 0.2 * -1}px`;
    }
  },
  mounted() {
    this.load();
    this.addEvents();
  },
  destroyed() {
    this.removeEvents();
  }
};
</script>

<style lang="scss" scoped>
.contents-board {
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  color: #2c2c2c;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .left-section,
  .right-section {
    height: 100%;
    margin: 0;
    flex: 1 1 auto;
    overflow: hidden;
    padding: 2.6vw 0;
  }

  .search-form {
    margin-bottom: 20px;
    input {
      width: 100%;
      outline: none;
      font-size: 12px;
      height: 34px;
      padding: 5px 32px 5px 5px;
    }
  }

  .filter-content-types {
    list-style: none;
    font-size: 0.88vw;
    padding: 0;
    margin: 0;

    li:not(:first-child) {
      margin-left: 18px;
    }

    label {
      color: #e13036;
    }
  }

  .columns-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;

    &.columns-event {
      width: 100%;
    }
  }

  .board-column {
    transition: transform 0.3s ease;
  }

  a {
    color: #2c2c2c;
    text-decoration: none;
  }

  .content {
    width: 100%;
    margin-bottom: 20px;
    padding: 5px;
    border: 1px solid #b9b9b9;
    margin-right: 3.52%;
    cursor: pointer;

    .content-thumbnail {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 0;
      padding-bottom: 66.93%;
      display: block;
      position: relative;
    }

    .content-type {
      background-color: #e13036;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 12px 12px 0 0;
      width: 100px;
      height: 22px;
      line-height: 1.8;
      text-align: center;
    }

    .content-title {
      font-size: 17px;
      font-weight: bold;
      margin: 0.5em 0;
    }

    .content-description {
      font-size: 12px;
    }

    .explore-button {
      background-color: #fff;
      border: 1px solid #e13036;
      color: #e13036;
      font-size: 14px;
      font-weight: 600;
      margin: 12px 0 0;
      padding: 5px;
      display: block;
      text-align: center;
      transition: 0.3s ease all;

      &:hover {
        background-color: #e13036;
        color: #fff;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .contents-board {
    padding: 0;
    flex-direction: row;
    align-items: flex-start;

    .left-section {
      width: 12.5vw;
      margin: 2.6vw 2.6vw 0;
      flex: 0 0 auto;
    }

    .right-section {
      flex: 1 1 auto;
    }

    .columns-container {
      width: 44.375vw;
      height: 100%;
      margin: 0 auto;
    }

    .board-column {
      width: calc(33% - 15px);
    }

    .content {
      width: 100%;
      margin-bottom: 4.22%;
      padding: 0.821%;
    }
  }
}
</style>
