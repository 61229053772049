<template>
  <div class="transition-slide">
    <div class="slide-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransitionSlide"
};
</script>

<style lang="scss">
.transition-slide {
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  visibility: unset;
  pointer-events: none;

  &.active {
    height: 100%;
    visibility: visible;
    pointer-events: initial;
  }

  .slide-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
  }
}
</style>
