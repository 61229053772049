<template>
  <div class="content" v-if="region">
    <div class="content-detail">
      <TransitionContainer>
        <TransitionSlide>
          <div id="getting-to-mongolia">
            <div class="getting-left" :style="{backgroundImage: 'url(' + regionHeader + ')'}"></div>
            <div class="getting-right">
              <div class="box">
                <img src="@/assets/img/Group 219.svg" style="width:350px;">
                <p class="description" data-aos="zoom-out">{{regionSubtitle}}</p>
              </div>
              <div class="box2">
                <h2 class="title-2" data-aos="zoom-out" data-aos-delay="300">{{regionTitle}}</h2>
              </div>
              <div class="box2">
                <p
                  class="description"
                  data-aos="zoom-out"
                  data-aos-delay="600"
                >{{regionDescription}}</p>
                <a class="down" href="#">
                  <img
                    src="@/assets/img/scrolldown-ground.png"
                    style="margin-top: 1vh; margin-left:35%"
                    @click="() => { scrollTo('#experiences'); }"
                  >
                </a>
              </div>
            </div>
          </div>

          <div class="custom-container" :style="{backgroundImage: 'url(' + regionHeader + ')'}">
            <div class="content-box">
              <div class="topic">
                <p
                  data-aos="zoom-out"
                  data-aos-delay="100"
                  class="aos-init aos-animate"
                  style="padding-top:10%;"
                >City name</p>
              </div>
              <div class="text">
                <h2
                  style="font-weight: bold; font-size: 48.83px; padding-left: 5%;"
                >Featured Experience</h2>
                <p style="padding-left: 5%; font-size: 15px; width:50%;">{{regionDescription}}</p>
              </div>
              <div class="text-box">
                <div class="text-red-box">
                  <div class="text-red-box-left">
                    <h2 style="font-weight: bold; font-size:30px">Highlights</h2>
                  </div>

                  <div class="text-red-box-right">
                    <p>{{regionDescription}}</p>
                    <a class="down" href="#">
                      <img
                        src="@/assets/img/scrolldown-ground.png"
                        style="margin-top: 1vh; margin-left:35%"
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TransitionSlide>
        <TransitionSlide>
          <ContentsBoard :locations="[region.id]"/>
        </TransitionSlide>
      </TransitionContainer>
    </div>
  </div>
</template>
<script>
import NavrightHome from "@/components/NavrightHome";
import ContentsBoard from "@/components/ContentsBoard";
import TransitionContainer from "@/components/TransitionContainer";
import TransitionSlide from "@/components/TransitionSlide";
import magnifier from "@/assets/img/698627-icon-111-search-512.png";
import map_mongolia from "@/assets/img/Group 219.svg";
import jQuery from "jquery";
import("aos").then(AOS => AOS.init());

export default {
  name: "ContactPage",
  components: {
    TransitionContainer,
    TransitionSlide,
    NavrightHome,
    ContentsBoard,
    map_mongolia,
    ContentsBoard
  },
  data() {
    return {
      region: null,
      regionTitle: "",
      regionSubtitle: "",
      regionDescription: "",
      regionHeader: "",
      magnifier,
      langs: [
        { id: "1", lname: "Experiences" },
        { id: "2", lname: "Activities" },
        { id: "3", lname: "Stories" },
        { id: "4", lname: "Offers" },
        { id: "5", lname: "Campaigns" }
      ],
      checked: []
    };
  },
  methods: {
    scrollTo(selector) {
      const $element = jQuery(selector);
      if ($element.length > 0) {
        jQuery("html, body").animate(
          {
            scrollTop: jQuery(selector).offset().top
          },
          1000
        );
      }
    }
  },
  mounted() {
    const name = this.$route.params.name;
    const locations = this.$store.state.ref.locations;
    this.region = locations.find(it => it.alias === name);
    if (this.region) {
      this.regionTitle = this.region.title;
      this.regionSubtitle = this.region.subtitle;
      this.regionDescription = this.region.description;
      this.regionHeader = this.region.header;
    }
  },
  computed: {
    checkAll: {
      get: function() {
        return this.langs ? this.checked.length == this.langs.length : false;
      },
      set: function(value) {
        var checked = [];
        if (value) {
          this.langs.forEach(function(lang) {
            checked.push(lang.id);
          });
        }
        this.checked = checked;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  margin: 0 40px 0 0;
}

#regions .content .content-detail {
  width: 100%;
  height: 100%;
  margin-top: -50%;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: flex;
}
#getting-to-mongolia {
  width: 100%;
  height: 100vh;
  display: flex;
}
#getting-to-mongolia .getting-left {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 60%;
  height: 100%;
  padding: 50px;
}
#getting-to-mongolia .getting-right {
  width: calc(60% - 80px);
  padding: 6.77vw 4.68vw 0;
  background-color: #e13036;
  color: #ffff;
  height: 100%;
}
#getting-to-mongolia .getting-right .box {
  margin-bottom: 30px;
}
#getting-to-mongolia .getting-right .box .description {
  font-size: 1.04vw;
  margin-top: 3.64vw;
  margin-bottom: 1.71vw;
  width: 100%;
}
#getting-to-mongolia .getting-right .box2 {
  margin-bottom: 30px;
}
#getting-to-mongolia .getting-right .box2 .title-2 {
  font-weight: bold;
  text-align: left;
}
#getting-to-mongolia .getting-right .box2 .description {
  font-size: 0.78125vw;
  width: 90%;
}
.custom-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: center;
  display: none;
}
.custom-container .content-box {
  left: 0%;
  top: 0%;
  width: 100%;
  height: 87.9%;
}
.custom-container .content-box .topic {
  padding-left: 5%;
  font-weight: bold;
  font-size: 30px;
  color: rgb(0, 0, 0);
}
.custom-container .content-box .text {
  padding-left: 5%;
  font-weight: bold;
  font-size: 30px;
  color: rgb(0, 0, 0);
  width: 70%;
  .title-1 {
    font-weight: bold;
    font-size: 30px;
  }
  .title-2 {
    font-weight: bold;
    font-size: 2.6vw;
  }
}
.custom-container .content-box .text-box .text-red-box {
  width: 100%;
  background-color: #e13036;
  color: #ffff;
  display: -webkit-box;
  display: flex;
  margin-top: 30%;
}
.custom-container .content-box .text-box .text-red-box .text-red-box-left {
  width: 40%;
  padding: 60px;
}
.custom-container .content-box .text-box .text-red-box .text-red-box-right {
  width: calc(40% - 50px);
  padding: 50px;
  width: 100%;
  padding-right: 10%;
}
#experiences {
  width: 100%;
  display: flex;
  padding: 6.77vw 0;
}

#experiences .check {
  font-size: 0.88vw;
}

#experiences .check h5 {
  color: #e13036;
  font-size: 0.88vw;
  font-weight: bold;
}

#experiences .check label {
  user-select: none;
  color: #e13036;
  cursor: pointer;
}

#experiences .check ul {
  list-style: none;
}

#experiences .check li {
  padding-left: 1.04vw;

  &:nth-child(1) {
    padding-left: 0;
  }
}

#experiences .check li + li {
  margin-top: 1.04vw;
}

#experiences .search-to-left {
  width: 20%;
  padding: 0 2.28%;
}

#experiences .search-box {
  padding: 5px 32px 5px 5px;
  margin-bottom: 1.04vw;
  border: 1px solid #9b9b9b;
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 0.625vw;
}

#experiences .search-right {
  padding: 0 2.6vw 2.6vw;
  width: 75%;
  color: #ffff;
}
@media only screen and (min-width: 1920px) {
  .box2 {
    .description {
      margin-left: 1%;
      padding-right: 5%;
    }
  }
}
</style>

